import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import WebFormList from '@/views/tools/web-form/components/webFormList/webFormList.vue'
import WebFormQuestionList from '@/views/tools/web-form/components/webFormQuestionList/webFormQuestionList.vue'
import GenerateLink from '@/views/tools/web-form/components/generateLink/generateLink.vue'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [WebFormList.name]: WebFormList,
    [WebFormQuestionList.name]: WebFormQuestionList,
    [GenerateLink.name]: GenerateLink
  },

  data: () => ({
    id: null,
    tabIndex: 0,
    subTabIndex: 0,
    showDeleteModal: false,
    formSelected: null
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Formulários',
            active: true
          }
        ]
      }
    }
  },

  methods: {
    showQuestionsFormWeb (formWeb) {
      this.formSelected = {}
      this.subTabIndex = 1
      this.formSelected = formWeb
    },
    selectRow(id) {
      this.showDeleteModal = true
      this.id = id
    },
    getAllActiveForms() {
      this.onClearformSelected()
      this.$refs.GenerateLink.getAllActiveForms()
    },
    onClearformSelected() {
      this.formSelected = null
    }
  }
}
