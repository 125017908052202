<template>
  <div>
    PROFESSORES
  </div>
</template>

<script>
export default {
  name: 'TeacherLink',

  props: {
    screen: {
      type: String,
      default: ''
    }
  },

  data: () => ({
  }),

  created() {
  },

  methods: {
  }
}
</script>