import FormWebQuestionModel from '@/shared/models/formWebQuestion'
import { noValue } from '@/shared/filters/NoValue'
import { IsSuccessCode } from '@/shared/utils/API'
import {toast, loading } from '@/shared/utils/UIHelper'
import WebFormRepository from '@/shared/http/repositories/socialProject/web-form'
import QuestionType from '@/shared/enums/questionType'

export default {
  name: 'WebFormQuestionList',

  props: {
    formSelected: {
      type: Object,
      default: {}
    }
  },

  watch: {
    formSelected: {
      handler: function (value) {
        if (value) {
          this.getAllQuestionsByFormWebID(value)
        } else {
          this.questions = []
          this.emptyQuestion = 'Selecione um formulário.'
        }
      },
      immediate: true
    }
  },

  data: () => ({
    id: null,
    title: 'Formulário Web Questão',
    questions: [],
    emptyQuestion: 'Selecione um formulário.',
    formWebQuestion: new FormWebQuestionModel(),
    showDeleteQuestionModal: false,
    questionTypeText: null,
    questionsFields: [
      {
        key: 'order',
        label: 'ORDEM',
        class: 'left-header-border text-center',
      },
      {
        key: 'questionTypeText',
        label: 'TIPO DA QUESTÃO',
        class: 'middle-header text-center',
      },
      {
        key: 'question',
        label: 'QUESTÃO',
        class: 'middle-header text-center',
      },
      {
        key: 'formWebQuestionAlternativesText',
        label: 'OPÇÕES',
        class: 'middle-header text-center',
        formatter: noValue
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 30,
      index: 1
    }
  }),

  provide() {
    return { parentValidator: this.$validator }
  },

  methods: {
    getNewQuestionRoute() {
      const formwebid = this.formSelected.id
      return { name: 'NewWebFormQuestion', params: { formwebid: formwebid } }
    },
    getEditQuestionRoute(questionID) {
      const formwebid = this.formSelected.id
      return { name: 'EditWebFormQuestion', params: { formwebid: formwebid, id: questionID } }
    },
    getAllQuestionsByFormWebID(value) {
      if (!value) return

      loading.push()
      WebFormRepository.GetAllQuestionsByFormWebID(value.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
          this.questions = res.data.data
          if (this.questions.length >= 1) {
            this.questions.forEach(el => {
              el.formWebQuestionAlternativesText = el.formWebQuestionAlternatives ? el.formWebQuestionAlternatives.join(' | ') : ''
              el.questionTypeText = this.getQuestionType(el.questionType)
            })
          } else {
            this.emptyQuestion =  'Formulário sem questão cadastrada!'
          }
          loading.pop()
          Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar questões', 'ERRO')
        })
    },
    getQuestionType(questionType) {
      const questionTypeValues = Object.values(QuestionType)
      const questionTypeObj = questionTypeValues.find(element => element.id === questionType)
  
      if(!questionTypeObj)
        return ''
  
      return questionTypeObj.name
    },
    onDeleteQuestion() {
      loading.push()
      console.log(this.id)
      WebFormRepository.DeleteQuestion(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getAllQuestionsByFormWebID(this.formSelected)
          this.showDeleteQuestionModal = false
          toast.success('Questão excluída com sucesso!', 'EXCLUSÃO DE QUESTÃO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir uma questão', 'ERRO')
        })
    },
    selectRow(id) {
      this.showDeleteQuestionModal = true
      this.id = id
    }
  }
}
