import {toast, loading } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import WebFormRepository from '@/shared/http/repositories/socialProject/web-form'
import WebFormDestination from '@/shared/enums/webFormDestination'
import StudentScreen from './screens/student/student.vue'
import TeacherScreen from './screens/teacher/teacher.vue'
import DetachedScreen from './screens/detached/detached.vue'
import ResponsibleScreen from './screens/responsible/responsible.vue'

export default {
  name: 'GenerateLink',
  components: {
    StudentScreen,
    TeacherScreen,
    DetachedScreen,
    ResponsibleScreen
  },

  data: () => ({
    destinationTab: [],
    screen: null,
    destinationDetails: null,
    tabIndex: 0
  }),

  methods: {
    getAllActiveForms() {
      this.destinationTab = []
      loading.push()
      WebFormRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if (res.data.data.length > 0) {
            res.data.data.forEach(el => {
              const alreadyAdded = this.destinationTab.some(x => x.destinationCode == el.destination) 
              const formSameDestination = res.data.data.filter(x => x.destination == el.destination).map(x => ({
                id: x.id,
                formName: x.name
              }))
  
              if (el.isActive && !alreadyAdded) {
                const destinationName = Object.values(WebFormDestination).find(x => x.id == el.destination).name.toUpperCase()
                this.destinationTab.push({
                  forms: formSameDestination,
                  destinationCode: el.destination,
                  destinationName: destinationName
                })
              }
            })
            this.onSetScreen(this.destinationTab[0])
          } else {
            this.destinationTab = []
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar formulários', 'ERRO')
        })
    },

    onSetTab(index) {
      return this.tabIndex = index
    },

    onSetScreen(toScreen) {
      if (!toScreen) return

      if (toScreen.destinationCode === 0) {
        console.log(toScreen)
        this.destinationDetails = toScreen
        this.screen = 'StudentScreen'
        return
      }

      if (toScreen.destinationCode === 1) {
        this.destinationDetails = toScreen
        this.screen = 'TeacherScreen'
        return
      }

      if (toScreen.destinationCode === 2) {
        this.destinationDetails = toScreen
        this.screen = 'ResponsibleScreen'
        return
      }

      if (toScreen.destinationCode === 3) {
        this.destinationDetails = toScreen
        this.screen = 'DetachedScreen'
        return
      }
    }
  }
}
