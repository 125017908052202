<template>
  <div>
    <hr>
    <div class="mb-3 text-right">
      <div class="d-flex justify-content-between">
        <div style="font-size: 30px;" v-b-tooltip.hover title="Colocar Texto">
          <b-icon class="cursor-pointer" icon="question-circle" variant="danger" />
        </div>
      </div>
    </div>

    <div>
      <div class="air__utils__textDivider mt-3 mb-3">
        <h6 class="air__utils__textDivider__content font-size-24 font-weight-bold">FORMULÁRIOS PARA ENVIO</h6>
      </div>
      <div v-for="(formDetail, idx) in destinationDetails.forms" :key="idx">
        <b-form-checkbox
          v-model="selectedFormsIdCheckBox"
          :value="formDetail.id"
        >
          {{ formDetail.formName }}
        </b-form-checkbox>
      </div>
      <div class="mt-3">
        <span class="required-field"> *</span>
        Referência do envio: 
        <span v-b-tooltip.hover title="Aqui será escolhido um nome para que no futuro as respostas deste formulário seja encontrado e até gerado gráficos. Não aparece no link">
          <b-icon class="cursor-pointer" icon="question-circle" variant="danger" />
        </span>
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <b-form-input
                id="refsend"
                name="sendRefName"
                v-model="sendRefName"
                type="text"
                placeholder="Ex: form acadêmico"
                data-vv-as="Referência do envio"
                v-validate="{ required: true }"
                :state="validateState('sendRefName')"
              ></b-form-input>
              <span v-show="veeErrors.has('sendRefName')" class="invalid-feedback-select">{{ veeErrors.first('sendRefName') }}</span>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div class="air__utils__textDivider mt-3 mb-3">
        <h6 class="air__utils__textDivider__content font-size-24 font-weight-bold">ESCOLHA OS ALUNOS</h6>
      </div>

      <!-- Aluno individual -->
      <b-row class="d-flex justify-content-center">
        <b-col cols="6" sm="5" md="5">
          <v-select id="txtIndividualStudentName"
            style="width: 100%;"
            class="ml-2"
            v-model="studentCriteria.name"
            :options="studentIndividualOptions"
            placeholder="Encontrar aluno por nome..."
            @search="onSearchStudentByName"
          >
            <template slot="no-options">
              Sem resultados..
            </template>
          </v-select>
        </b-col>
        <b-col cols="1" sm="1" md="1">
          <b-button @click="onAddTableStudentSelecteds(studentCriteria.name)">
            <i class="fa fa-plus-circle" aria-hidden="true"></i>
          </b-button>
        </b-col>
        <b-col cols="6" sm="5" md="5">
          <v-select id="txtIndividualStudentName" style="width: 100%;"
            v-model="classCriteria.name"
            :options="classOptions"
            placeholder="Encontrar aluno por turma..."
            @search="onSearchClassByName"
          >
            <template slot="no-options">
              Sem resultados..
            </template>
          </v-select>
        </b-col>
        <b-col cols="1" sm="1" md="1">
          <b-button @click="onSearchStudentsOnClass(classCriteria.name)">
            <i class="fa fa-search" aria-hidden="true"></i>
          </b-button>
        </b-col>
      </b-row>

      <!-- Aluno por turma -->
      <b-row class="mb-3">
        <b-col cols="6">
          <b-row class="d-flex justify-content-center">
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table
            id="students-class"
            ref="studentsByClass"
            :items="studentsByclass"
            :fields="fieldsStudentsByClass"
            responsive
            small
            show-empty
            selectable
            @row-clicked="onSelectRow"
            class="cursor-pointer"
            hover
          >
          <template v-slot:cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
            </template>
          </template>

          <template v-slot:empty>
            <h6 class="text-center">{{ emptyTextStudentsByClass }}</h6>
          </template>
          </b-table>
        </b-col>
      </b-row>
    </div>

    <div class="air__utils__textDivider mt-3 mb-3">
      <h6 class="air__utils__textDivider__content font-size-24 font-weight-bold">ALUNOS SELECIONADOS</h6>
    </div>

    <div>
      <b-table
        id="student-table"
        :items="studentsSelected"
        :fields="fieldsStudentsSelected"
        striped
        responsive
        small
        hover
        primary-key="id"
        show-empty
      >
      <template v-slot:cell(action)="row">
        <b-btn
          size="sm"
          variant="secondary"
          class="mr-2"
          v-b-tooltip.bottom="'Remover Aluno'"
          @click="onRemoveStudentFromTable(row.item)"
        >
          <b-icon icon="trash-fill" variant="light"></b-icon>
        </b-btn>
      </template>
        <template v-slot:empty>
          <h6 class="text-center">{{ emptyTextTableSelectedStudent }}</h6>
        </template>
      </b-table>
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column mr-3">
          <div class="font-weight-bold">REGISTROS:</div>
          <div class="text-center border bg-light">{{ studentsSelected.length }}</div>
        </div>
      </div>
    </div>

    <div class="text-right">
      <b-button variant="success" @click="onSaveStudentsForm">
        Salvar
      </b-button>
    </div>
  </div>
</template>

<script>
import {toast, loading } from '@/shared/utils/UIHelper'
import StudentRepository from '@/shared/http/repositories/socialProject/student'
import { IsSuccessCode } from '@/shared/utils/API'
import StudentCriteria from '@/shared/models/criteria/studentcriteria'
import ClassCriteria from '@/shared/models/criteria/classcriteria'
import ClassRepository from '@/shared/http/repositories/socialProject/class'
import ClassRegistrationRepository from '@/shared/http/repositories/socialProject/classregistration'
import debounce from 'lodash/debounce'
import Pagination from '@/components/layout/Pagination/pagination.vue'

export default {
  components: {
    [Pagination.name]: Pagination,
  },
  name: 'StudentLink',

  props: {
    screen: {
      type: String,
      default: ''
    },
    destinationDetails: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    studentCriteria: new StudentCriteria(),
    classCriteria: new ClassCriteria(),
    selectedFormsIdCheckBox: [],
    studentsSelected: [],
    studentsByclass: [],
    studentIndividualOptions: [],
    classOptions: [],
    sendRefName: null,
    emptyTextTableSelectedStudent: 'Selecione os alunos para enviar o LINK',
    emptyTextStudentsByClass: 'Selecione uma turma',
    emptyTextClass: 'Digite o nome de uma turma',
    fieldsStudentsSelected: [
      {
        key: 'code',
        label: 'CÓDIGO',
        class: 'left-header-border text-center',
        sortable: true
      },
      {
        key: 'studentName',
        label: 'ALUNO',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    fieldsStudentsByClass: [
      {
        key: 'selected',
        label: 'SELEÇÃO',
        class: 'left-header-border text-center'
      },
      {
        key: 'code',
        label: 'CÓDIGO',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'gender',
        label: 'GÊNERO',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'label',
        label: 'ALUNO',
        class: 'right-header-border text-center'
      }
    ]
  }),
  watch: {
    'studentCriteria.name': {
      handler: function (value) {
        if (!value) {
          this.studentIndividualOptions = []
        }
      },
      immediate: true
    },
    'classCriteria.name': {
      handler: function (value) {
        if (!value) {
          this.classOptions = []
        }
      },
      immediate: true
    }
  },
  methods: {
    onRemoveStudentFromTable(row) {
      const idxToRemoveTableStudentSelecteds = this.studentsSelected.findIndex(x => x.code == row.code)
      this.studentsSelected.splice(idxToRemoveTableStudentSelecteds, 1)

      if (this.studentsByclass.length > 0) {
        const idxToRemoveTableStudentByClass = this.studentsByclass.findIndex(x => x.code == row.code)

        if (idxToRemoveTableStudentByClass !== -1) {
          let tableStudentByClass = this.$refs.studentsByClass
          if (tableStudentByClass.items[idxToRemoveTableStudentByClass]) {
            // eslint-disable-next-line no-debugger
            debugger
            console.log('this.$refs.studentsByClass', this.$refs.studentsByClass)
            this.$refs.studentsByclass.selectedRows[idxToRemoveTableStudentByClass] = false
          }
        }
      }
    },
    onAddTableStudentSelecteds(item) {
      if (!item) return
      const hasAdded = this.studentsSelected.some(x => x.code == item.code)

      if (hasAdded) {
        toast.info('Aluno já adicionado', 'LINK PARA ALUNO')
        return
      }


      this.studentsSelected.push({
        code: item.code,
        id: item.id,
        studentName: item.label,
        studentValidationRg: item.studentValidationRg
      })

      this.studentIndividualOptions = []
      this.studentCriteria = new StudentCriteria()
    },
    onSearchStudentsOnClass(item) {
      if (!item) return

      loading.push()
      ClassRegistrationRepository.GetByClass(item.id)
      .then(res => {
        if (!IsSuccessCode(res)) return Promise.reject()

          if (res.data.data.length > 0) {
            const remap = res.data.data.map(x => ({
              id: x.id,
              classId: x.classId,
              studentId: x.studentId,
              code: x.studentCode,
              label: x.studentName,
              gender: x.studentGender,
              age: x.studentAge,
              birthDate: x.birthDate,
              studentValidationRg: x.documentRG
            }))

            this.studentsByclass = remap
          } else {
            this.studentsByclass = []
          }
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro pegar alunos por turma', 'ERRO')
        })
    },
    onSearchStudentByName(searchText) {
      if (searchText.length) {
        this.studentsByclass = []
        this.classOptions = []
        this.classCriteria = new ClassCriteria()
        this.searchStudent(searchText, this)
      }
    },
    searchStudent: debounce(async (searchValue, vm) => {
      try {
        loading.push()
        const res = await StudentRepository.GetByName(searchValue)
        let data = res.data.data
        let options = data.map(opt => ({ label: opt.name, id: opt.id, code: opt.code, studentValidationRg: opt.documentRG }))

        vm.studentIndividualOptions = options

        if (searchValue) {
          vm.studentCriteria.name = options.find(o => o.name === searchValue)
        }
      } catch (err) {
        toast.error('Erro ao carregar aluno', 'ERRO')
        loading.pop()
      } finally {
        loading.pop()
      }
    }, 850),
    onSearchClassByName(searchText) {
      if (searchText.length) {
        this.studentIndividualOptions = []
        this.studentCriteria = new StudentCriteria()
        this.searchClass(searchText, this)
      }
    },
    searchClass: debounce(async (searchValue, vm) => {
      try {
        loading.push()
        const currentClassCriteria = new ClassCriteria()
        currentClassCriteria.name = searchValue
        const res = await ClassRepository.GetAll(currentClassCriteria)
        let data = res.data.data
        let options = data.map(opt => ({ label: opt.name, id: opt.id, modalityCategoryName: opt.modalityCategoryName, studentValidationRg: opt.documentRG }))

        vm.classOptions = options

        if (searchValue) {
          vm.classCriteria.name = options.find(o => o.name === searchValue)
          console.log(vm.classCriteria)
        }
      } catch (err) {
        toast.error('Erro ao carregar turma', 'ERRO')
        loading.pop()
      } finally {
        loading.pop()
      }
    }, 850),
    onSaveStudentsForm () {
      if (this.studentsSelected.length === 0) {
        toast.warning('Selecione pelo menos um aluno para gerar o link', 'GERAR DE LINK')
        return
      }
      
      if (this.selectedFormsIdCheckBox.length === 0) {
        toast.warning('Selecione pelo menos um formulário', 'GERAR DE LINK')
        return
      }

      this.$validator.validateAll().then(result => {
        if (result) {
          console.log('Ok')
        } else {
          toast.warning('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
        }
      })

      const objStudent = this.studentsSelected.map(x => ({
        studentId: x.id,
        studentValidationRg: x.studentValidationRg
      }))

      this.saveStudentForm(objStudent)
    },
    saveStudentForm (objStudent) {
      const entity = {
        formWebId: this.selectedFormsIdCheckBox,
        students: objStudent,
        refName: this.sendRefName
      }

      console.log(entity)
    },
    onSelectRow(item) {
      if (!item) return
      const hasSelectedIdx = this.studentsSelected.findIndex(x => x.studentName == item.label)

      if (hasSelectedIdx !== -1) {
        this.studentsSelected.splice(hasSelectedIdx, 1)
      } else {
        this.onAddTableStudentSelecteds(item)
      }
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
}
</script>