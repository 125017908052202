import {toast, loading } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import WebFormRepository from '@/shared/http/repositories/socialProject/web-form'
import WebFormModel from '@/shared/models/webForm'
import WebFormDestination from '@/shared/enums/webFormDestination'

export default {
  name: 'WebFormList',
  
  data: () => ({
    emptyForm: 'Nenhum Formulário Cadastrado',
    webFormList: [],
    placeholderNewWebForm: 'Insira um nome para o novo formulário',
    titleNewWebForm: 'Formulário WEB',
    isNewWebForm: true,
    showDeleteModal: false,
    webFormModel: new WebFormModel(),
    fieldsWebFormList: [
      {
        key: 'isActive',
        label: 'FORMS. ATIVO',
        class: 'left-header-border text-center',
      },
      {
        key: 'destination',
        label: 'DESTINADO À',
        class: 'middle-header text-center',
        formatter: (value, key, item) => {
          if (!item) return 'N/D'

          const enumsWebForm = Object.values(WebFormDestination).find(x => x.id == item.destination).name.toUpperCase()
          return enumsWebForm
        }
      },
      {
        key: 'name',
        label: 'FORMULÁRIO',
        class: 'middle-header text-center',
        formatter: (value, key, item) => {
          if (!item) return 'N/D'

          return item.name.toUpperCase()
        }
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ]
  }),

  created() {
    this.getAll()
  },

  methods: {
    getAll() {
      loading.push()
      WebFormRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.webFormList = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar formulários', 'ERRO')
        })
    },
    changeActiveForm (item) {
      loading.push()
      WebFormRepository.ChangeActiveForm(item.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
        
          toast.success('Formulário alterado com sucesso!', 'FORMULÁRIO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao alterar formulário', 'ERRO')
        })
    },
    onDelete() {
      loading.push()
      WebFormRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getAll()
          this.showDeleteModal = false
          toast.success('Formulário excluído com sucesso!', 'EXCLUSÃO DE FORMULÁRIO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir um formulário', 'ERRO')
        })
    },
    onToQuestionsTable(formWeb) {
      this.$emit('onShowQuestionsFormWeb', formWeb)
    },
    selectRow(id) {
      this.showDeleteModal = true
      this.id = id
    }
  }
}
